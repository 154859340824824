import React, { forwardRef } from 'react';
import { Input as TamaguiInput, Label, type TamaguiElement, XStack, YStack, type YStackProps } from 'tamagui';
import type { InputProps as TamaguiInputProps } from 'tamagui';
import { skeleton } from '../../skeleton';
import type { IconName } from '../Icon/Icon';
import Icon from '../Icon/Icon';
import Text from '../Text/Text';

export type InputType = 'password' | 'email' | 'number' | 'tel' | 'url';
type Variant = 'default' | 'rounded';

export type BaseInputProps = TamaguiInputProps & {
  variant?: Variant;
  iconBefore?: IconName;
  iconAfter?: IconName;
  type?: InputType;
  label?: string;
  error?: string;
  wrapperProps?: YStackProps;
  loading?: boolean;
};

const variantProps: {
  [key in Variant]: TamaguiInputProps;
} = {
  default: {
    borderColor: '$border',
    px: '$4',
  },
  rounded: {
    borderRadius: 10000,
  },
};

const BaseInput = forwardRef<TamaguiElement, BaseInputProps>(
  (
    { variant = 'default', iconBefore, iconAfter, type, label, error, wrapperProps = {}, id, loading, ...restProps },
    ref,
  ) => {
    return (
      <YStack position="relative" flex={1} {...wrapperProps}>
        {!loading && iconBefore && (
          <XStack
            position="absolute"
            top={0}
            left={variant === 'rounded' ? '$4' : 12}
            height="100%"
            alignItems="center"
          >
            <Icon icon={iconBefore} color="$darkGray" size={12} />
          </XStack>
        )}

        {!loading && iconAfter && (
          <XStack
            position="absolute"
            top={0}
            right={variant === 'rounded' ? '$4' : 12}
            height="100%"
            alignItems="center"
          >
            <Icon icon={iconAfter} color="$darkGray" size={12} />
          </XStack>
        )}

        {label && (
          <Label cursor="pointer" htmlFor={id} fontSize="$10" color="$contrast" marginBottom="$2">
            {label}
          </Label>
        )}

        {loading ? (
          <YStack height="$11" className={skeleton.className('box', true)} flex={1} width="100%" {...wrapperProps} />
        ) : (
          <TamaguiInput
            // @ts-ignore
            ref={ref}
            id={id}
            borderRadius={0}
            borderWidth={1}
            fontSize="$12"
            color={error ? '$error' : '$contrast'}
            width="100%"
            height="$11"
            placeholderTextColor="$darkGray"
            secureTextEntry={type === 'password'}
            hoverStyle={{ borderColor: '$border' }}
            focusStyle={{
              borderColor: '$basePale',
              borderWidth: 1,
              outlineWidth: 0,
            }}
            {...variantProps[variant]}
            {...(iconBefore && {
              paddingLeft: variant === 'rounded' ? '$9' : 8 * 4,
            })}
            {...(error && { borderColor: '$error' })}
            {...restProps}
          />
        )}

        {error && (
          <Text color="$error" fontSize="$10" position="absolute" top="calc(100% + 4px)">
            {error}
          </Text>
        )}
      </YStack>
    );
  },
);

export default BaseInput;
